import React from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Spin, Button, } from "antd";
import { withAlert } from 'react-alert';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';

const FormItem = Form.Item;

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.formRef = React.createRef();
  }

  OnCreateInquiry = (e) => {
    httpPost(httpUrl.inquiryCreate, [], { category: 'home', title: '문의', ...this.formRef.current.getFieldsValue() }).then(response => {
      console.log('result=' + JSON.stringify(response.data))
      if (response.data == "SUCCESS") {
        alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
        this.formRef.current.resetFields();
      }
      else {
        alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
      }
    }).catch(e => {
      console.log('err=' + JSON.stringify(e))
      alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
    })

  }

  render() {
    return (

      <div class="root">

        <div class="header">

          <div class="header-container">

            <div class="header-logo">
              <img src={require("../../img/logo.png").default} />
            </div>

            <div class="header-txt-main">
              Blockhchain<br />
                  Technology
              </div>

            <div class="header-bar"></div>

            <div class="header-txt-main-sub desk">
              고객의 성공과 함께 성장하는 기업 (주)블릭트
              </div>

            <div class="header-txt-main-sub mobile">
              고객의 성공과 함께 <br />성장하는 기업<br /> (주)블릭트
              </div>

            <div class="header-contents desk">

              <div class="header-contents-box">
                <div class="header-contents-txt-title">
                  플랫폼 사업
                      </div>

                <div class="header-contents-txt-sub">
                  블릭트의 독자적인 기술을 <br />
                          기반으로 차별화된 <br />
                          서비스를 제공합니다.
                      </div>

              </div>

              <div class="header-contents-box1">
                <div class="header-contents-txt-title">
                  파트너쉽 사업
                      </div>

                <div class="header-contents-txt-sub">
                  다양한 산업 분야의 기업과 <br />
                          협업하여 IT 컨버전스를 <br />
                          구현합니다.
                      </div>

              </div>

              <div class="header-contents-box">
                <div class="header-contents-txt-title">
                  IT 아웃소싱
                      </div>

                <div class="header-contents-txt-sub">
                  품질을 최고의 가치로 <br />
                          완벽한 SW 시스템을 <br />
                          구축합니다.

                      </div>

              </div>

            </div>

            <div class="header-contents mobile">

              <div class="header-contents-box">
                <div class="header-contents-txt-title">
                  플랫폼 사업
                      </div>

                <div class="header-contents-txt-sub">
                  블릭트의 독자적인 <br />
                          기술을 기반으로 <br />
                          차별화된 서비스를 <br />제공합니다.
                      </div>

              </div>

              <div class="header-contents-box1">
                <div class="header-contents-txt-title">
                  파트너쉽 사업
                      </div>

                <div class="header-contents-txt-sub">
                  다양한 산업 분야의 <br />
                          기업과 협업하여 <br />IT 컨버전스를 <br />
                          구현합니다.
                      </div>

              </div>

              <div class="header-contents-box">
                <div class="header-contents-txt-title1">
                  IT 아웃소싱
                      </div>

                <div class="header-contents-txt-sub">
                  품질을 최고의 가치로 <br />
                          완벽한 SW 시스템을 <br />
                          구축합니다.

                      </div>

              </div>

            </div>


          </div>

        </div>

        <div class="vision desk">

          <div class="vision-container">

            <div class="vision-title">
              VISION
              </div>

            <div class="vision-bar"></div>
            <RevealSlide bottom duration={1500}>
              <div>
                <div class="vision-title-sub">
                  블릭트는 <span>최신 IT 기술 트렌드를 반영한 기술력 확보</span>를 최우선으로 하며, <br />
                  블록체인, 비전분석, AI, 빅데이터 등의 기술을 기반으로 한 자체 플랫폼을 지속적으로 확보해 나갈 예정이며, <br />
                  비IT 영역에 대해서는 <span>다양한 산업분야의 기업들과 파트너쉽을 구축</span>하여 사업을 추진합니다. <br />
                  또한 개발인력을 지속적으로 육성하여 <span>IT아웃소싱을 통한 다양한 분야의 고객을 확보</span>하여<br />
                  <span>IT Total Service를 제공할 수 있는 기업</span>으로 성장해 나갈 것입니다.<br />
                </div>
              </div>
            </RevealSlide>
            <div class="vision-contents-title">
              IT Total Service
              </div>

            <RevealSlide bottom duration={1500}>
            <div>
            <div class="visoion-contents">

              <div class="visoion-contents-box">

                <div class="visoion-contents-box-ko">
                  플랫폼 사업
                      </div>

                <div class="visoion-contents-box-en">
                  Platform Business
                      </div>

              </div>

              <div class="visoion-contents-box1">

                <div class="visoion-contents-box-ko">
                  파트너쉽 사업
                      </div>

                <div class="visoion-contents-box-en">
                  Partnership Business
                      </div>

              </div>

              <div class="visoion-contents-box">

                <div class="visoion-contents-box-ko">
                  IT 아웃소싱
                      </div>

                <div class="visoion-contents-box-en">
                  IT Outsourcing
                      </div>

              </div>

            </div>

            <div class="vision-contents-sub-box">

              <ul class="vision-contents-sub-box-txt">
                <li>장기적으로 자체 서비스를 확보</li>
                <li>기술 기반으로 차별화된 서비스 제공</li>
                <li>언택트와 같은 환경적 변화에 <br /> 빠르게 대응 가능</li>
              </ul>

              <ul class="vision-contents-sub-box-txt1">
                <li>비IT와 IT기술의 컨버전스를 통해 <br />새로운 사업 발굴</li>
                <li>다양한 분야의 기업과 파트너쉽 구축</li>
              </ul>

              <ul class="vision-contents-sub-box-txt">
                <li>품질을 최고의 가치로 하여 <br />완성도 있는 SW공급</li>
                <li>꾸준한 개발인력 양성을 통한 고객선도</li>
              </ul>

            </div>

            <div class="vision-contents-white-box">

              <div class="vision-contents-white-box-txt">
                블록체인
                  </div>

              <div class="vision-contents-white-box-txt-2">
                /
                  </div>

              <div class="vision-contents-white-box-txt">
                빅데이터
                  </div>

              <div class="vision-contents-white-box-txt-2">
                /
                  </div>

              <div class="vision-contents-white-box-txt">
                AI
                  </div>

              <div class="vision-contents-white-box-txt-2">
                /
                  </div>

              <div class="vision-contents-white-box-txt">
                비전분석
                  </div>

            </div>

            </div>
            </RevealSlide>

          </div>

        </div>

        <div class="vision mobile">

          <div class="vision-container">

            <div class="vision-title">
              VISION
              </div>

            <div class="vision-bar"></div>

            <RevealSlide bottom duration={1500}>
              <div>
            <div class="vision-title-sub">
              블릭트는 <span>최신 IT 기술 트렌드를 반영한 기술력 <br /> 확보</span>를 최우선으로 하며,
                  블록체인, 비전분석, AI, <br /> 빅데이터 등의 기술을 기반으로 한 자체 플랫폼을 <br /> 지속적으로 확보해 나갈 예정이며,
                  비IT 영역에 <br /> 대해서는<span>다양한 산업분야의 기업들과 파트너쉽을 <br />구축</span>하여 사업을 추진합니다.
                  또한 개발인력을 <br /> 지속적으로 육성하여 <span>IT아웃소싱을 통한 다양한 <br /> 분야의 고객을 확보</span>하여
                  <span>IT Total Service를 <br />제공할 수 있는 기업</span>으로성장해 나갈 것입니다.<br />
            </div>
            </div>
            </RevealSlide>

            <div class="vision-contents-title">
              IT Total Service
              </div>

              <RevealSlide bottom duration={1500}>
            <div>
            <div class="visoion-contents">

              <div class="visoion-contents-box">

                <div class="visoion-contents-box-ko">
                  플랫폼 사업
                      </div>

                <div class="visoion-contents-box-en">
                  Platform Business
                      </div>

                <div class="vision-contents-sub-box">

                  <ul class="vision-contents-sub-box-txt">
                    <li>장기적으로 자체 서비스를 확보</li>
                    <li>기술 기반으로 차별화된 서비스 제공</li>
                    <li>언택트와 같은 환경적 변화에 빠르게 대응 가능</li>
                  </ul>

                </div>

              </div>

              <div class="visoion-contents-box1">

                <div class="visoion-contents-box-ko">
                  파트너쉽 사업
                      </div>

                <div class="visoion-contents-box-en">
                  Partnership Business
                      </div>


                <div class="vision-contents-sub-box">

                  <ul class="vision-contents-sub-box-txt">
                    <li>비IT와 IT기술의 컨버전스를 통해 새로운 사업 발굴</li>
                    <li>다양한 분야의 기업과 파트너쉽 구축</li>
                  </ul>

                </div>

              </div>

              <div class="visoion-contents-box">

                <div class="visoion-contents-box-ko">
                  IT 아웃소싱
                      </div>

                <div class="visoion-contents-box-en">
                  IT Outsourcing
                      </div>

                <div class="vision-contents-sub-box">

                  <ul class="vision-contents-sub-box-txt">
                    <li>품질을 최고의 가치로 하여 완성도 있는 SW공급</li>
                    <li>꾸준한 개발인력 양성을 통한 고객선도</li>
                  </ul>

                </div>

              </div>

            </div>

            </div>
            </RevealSlide>

            <div class="vision-contents-white-box">

              <div class="vision-contents-white-box-txt1">
                블록체인
                  </div>

              <div class="vision-contents-white-box-txt">
                빅데이터
                  </div>

              <div class="vision-contents-white-box-txt1">
                AI
                  </div>

              <div class="vision-contents-white-box-txt">
                비전분석
                  </div>

            </div>


          </div>

        </div>

        <div class="platform">

          <div class="platform-contents">

            <div class="platform-contents-txt">

              <div class="platform-contents-txt-box">

                <div class="platform-contents-txt-box-ko">
                  플랫폼사업
                      </div>
                <div class="platform-contents-txt-box-en">
                  Platform Business
                      </div>

              </div>

              <div class="platform-contents-txt-title">
                블록체인 기반<br />
                      정품인증 시스템
                  </div>

              <div class="platform-contents-txt-day">
                (`21. 01 오픈 예정)
                  </div>

              <div class="platform-contents-txt-sub desk">
                제품에 부착된 QR코드를 촬영하여 누구나 손쉽게 <br />
                      제품의 정품여부를 확인할 수 있습니다. <br />
                      또한, 정품정보를 블록체인에 기록하여 신뢰성을 제고하고, <br />
                      유통 이력을 기록하여 구매자에게 제공합니다.

                  </div>

              <div class="platform-contents-txt-sub mobile">
                제품에 부착된 QR코드를 촬영하여 <br /> 누구나 손쉽게
                      제품의 정품여부를<br /> 확인할 수 있습니다.
                      또한, 정품정보를 블록체인에 <br />기록하여 신뢰성을 제고하고, <br />
                      유통 이력을 기록하여 구매자에게 제공합니다.

                  </div>

            </div>

            <div class="platform-contents-img">
            <Bounce duration={1500}>
              <img src={require("../../img/platform_mockup.png").default} />
            </Bounce>
            </div>
          </div>

        </div>

        <div class="partnership desk">

          <div class="partnership-contents">

            <div class="partnership-contents-img">
                <Flip left duration={1500}>
              <img src={require("../../img/partnership_mockup.png").default} />
                </Flip>
            </div>

            <div class="partnership-contents-txt">

              <div class="partnership-contents-txt-box">

                <div class="partnership-contents-txt-box-ko">
                  파트너쉽사업
                      </div>
                <div class="partnership-contents-txt-box-en">
                  Partnership Business
                      </div>

              </div>

              <div class="partnership-contents-txt-title">
                스마트 건물관리<br />
                      플랫폼
                  </div>

              <div class="partnership-contents-txt-sub">
                건물관리 전문업체와 함께 추진하고 있는 사업으로 <br />
                      현장 사진관리, 위치기반 근태관리 등의 기능에 <br />
                      AI 및 비전분석 기능을 도입하여 효율적으로 <br />
                      현장업무를 관리할 수 있는 서비스를 제공합니다.
                  </div>

            </div>


          </div>

        </div>

        <div class="partnership mobile">

          <div class="partnership-contents">

            <div class="partnership-contents-txt">

              <div class="partnership-contents-txt-box">

                <div class="partnership-contents-txt-box-ko">
                  파트너쉽사업
                      </div>
                <div class="partnership-contents-txt-box-en">
                  Partnership Business
                      </div>

              </div>

              <div class="partnership-contents-txt-title">
                스마트 건물관리<br />
                      플랫폼
                  </div>

              <div class="partnership-contents-txt-sub">
                건물관리 전문업체와 함께 추진하고 있는 사업으로 <br />
                      현장 사진관리, 위치기반 근태관리 등의 기능에 <br />
                      AI 및 비전분석 기능을 도입하여 효율적으로 <br />
                      현장업무를 관리할 수 있는 서비스를 제공합니다.
                  </div>

            </div>

            <div class="partnership-contents-img">
                <Flip left duration={1500}>
              <img src={require("../../img/partnership_mockup.png").default} />
              </Flip>
            </div>

          </div>

        </div>

        <div class="outsourcing">

          <div class="outsourcing-contents">

            <div class="outsourcing-contents-txt">

              <div class="outsourcing-contents-txt-box">

                <div class="outsourcing-contents-txt-box-ko">
                  IT 아웃소싱
                      </div>
                <div class="outsourcing-contents-txt-box-en">
                  IT Outsourcing
                      </div>

              </div>

              <div class="outsourcing-contents-txt-title desk">
                암호화폐 결제 및 정산 시스템
                  </div>

              <div class="outsourcing-contents-txt-title mobile">
                암호화폐 결제 및 <br /> 정산 시스템
                  </div>

              <div class="outsourcing-contents-txt-sub desk">
                온라인 쇼핑몰 및 오프라인 가맹점의 QR코드를 촬영하여 내 지갑에 보유하고 있는 <br />
                      암호화폐로 결제할 수 있습니다. 결제한 암호화폐는 매일 정산하여 가맹점으로 현금이 지급됩니다.
                  </div>

              <div class="outsourcing-contents-txt-sub mobile">
                온라인 쇼핑몰 및 오프라인 가맹점의 QR코드를 <br />촬영하여 내 지갑에 보유하고 있는
                      암호화폐로<br /> 결제할 수 있습니다. 결제한 암호화폐는 매일 <br />정산하여 가맹점으로 현금이 지급됩니다.
                  </div>

            </div>

            <div class="outsourcing-contents-img desk">
            <RevealSlide right duration={1500}>
              <img src={require("../../img/out_mockup.png").default} />
            </RevealSlide>
            </div>

            <div class="outsourcing-contents-img mobile">
            <RevealSlide right duration={1500}>
              <img src={require("../../img/out_mockup-m.png").default} />
            </RevealSlide>
            </div>

          </div>

        </div>

        <div class="history desk">

          <div class="history-title">
            HISTORY
          </div>

          <RevealFade bottom duration={1500}>
            <div>
          <div class="history-contents">


            <div class="history-contents-namebox">

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2018. 08
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  카드형 콜드월렛 출시
                      </div>

              </div>

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2019. 02
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  DAPP 솔루션 개발
                      </div>

              </div>

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2019. 07
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  디헝네트워크 MOU
                      </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 05
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  (주)블릭트 설립
                      </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 08
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  플랫폼 사업 발족
                      </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 10
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  파트너쉽 체결
                      </div>

              </div>

            </div>

            <div class="history-contents-dotbox">

              <div class="history-contents-dotbox-bar">

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-01.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-02.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-03.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-04.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-05.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-06.png").default} />
                </div>

              </div>

            </div>

            <div class="history-contents-subbox">

              <div class="history-contents-subbox-txt">
                비트코인, 이더리움 등 다양한 암호화폐를 <br />
                      오프라인 카드에 보관할 수 있는 제품
                  </div>

              <div class="history-contents-subbox-txt">
                블록체인 플랫폼과 연동하여 결제처리, 이력기록 등을 <br />
                      수행할 수 있는 모바일 기반 탈중앙화 앱 개발
                  </div>

              <div class="history-contents-subbox-txt">
                메인넷 및 블록체인 기술 개발을 위한 <br />
                      중국 강소성 물류회사와 전략적 제휴
                  </div>

              <div class="history-contents-subbox-txt-b">
                블릭트를 설립하여 블록체인 이외 다양한 분야로 <br />
                      사업범위를 확장
                  </div>

              <div class="history-contents-subbox-txt-b">
                자체 수익모델 확보를 위해 개발팀을 구성하여 <br />
                      서비스 개발 착수 (블록체인 기반 정품인증 서비스)
                  </div>

              <div class="history-contents-subbox-txt-b">
                다양한 분야의 사업을 추진하기 위하여 <br />
                      커넥트9, BJ, 더브라이트, 한중모터스 등과 파트너쉽을 <br />
                      체결하여 공동개발 추진
                  </div>
            </div>

          </div>

          </div>
            </RevealFade>
        </div>

        <div class="history mobile">

          <div class="history-title">
            HISTORY
          </div>

          <RevealFade bottom duration={1500}>
          <div class="history-contents">

            <div class="history-contents-dotbox">

              <div class="history-contents-dotbox-bar">

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-01-m.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-02-m.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-03-m.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-04-m.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-05-m.png").default} />
                </div>

                <div class="history-contens-dotbox-img">
                  <img src={require("../../img/history-dot-06-m.png").default} />
                </div>

              </div>

            </div>

            <div class="history-contents-namebox">

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2018. 08
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  카드형 콜드월렛 출시
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt">
                    비트코인, 이더리움 등 다양한 <br /> 암호화폐를
                              오프라인 카드에<br /> 보관할 수 있는 제품
                              <br />
                  </div>

                </div>

              </div>

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2019. 02
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  DAPP 솔루션 개발
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt">
                    블록체인 플랫폼과 연동하여 결제처리, <br />이력기록 등을
                              수행할 수 있는 <br />모바일 기반 탈중앙화 앱 개발
                          </div>

                </div>

              </div>

              <div class="history-contents-namebox-wrap">

                <div class="history-contents-namebox-wrap-day">
                  2019. 07
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  디헝네트워크 MOU
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt">
                    메인넷 및 블록체인 기술 <br /> 개발을 위한
                              중국 강소성 물류회사와 <br />전략적 제휴
                          </div>

                </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 05
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  (주)블릭트 설립
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt-b">
                    블릭트를 설립하여 블록체인 이외 <br />다양한 분야로
                              사업범위를 확장

                              <br />
                  </div>

                </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 08
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  플랫폼 사업 발족
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt-b">
                    자체 수익모델 확보를 위해 개발팀을  <br />구성하여
                              서비스 개발 착수<br /> (블록체인 기반 정품인증 서비스)
                          </div>

                </div>

              </div>

              <div class="history-contents-namebox-wrap-b">

                <div class="history-contents-namebox-wrap-day">
                  2020. 10
                      </div>

                <div class="history-contents-namebox-wrap-txt">
                  파트너쉽 체결
                      </div>

                <div class="history-contents-subbox">

                  <div class="history-contents-subbox-txt-b">
                    다양한 분야의 사업을 추진하기 위하여 <br />
                              커넥트9, BJ, 더브라이트, 한중모터스 등과 <br /> 파트너쉽을
                              체결하여 공동개발 추진
                          </div>

                </div>

              </div>

            </div>




          </div>
          </RevealFade>
        </div>

        <div class="contactus">

          <div class="contactus-title">
            CONTACT US
          </div>

          <div class="contactus-title-bar"></div>


          <Form ref={this.formRef} onFinish={this.OnCreateInquiry}>

            <div class="contactus-box">

              <div class="contactus-box-left">

                <div class="contactus-form">

                  <FormItem
                    name="company"
                    rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                    <input className="contactus-input" placeholder="이름 / 회사명" />
                  </FormItem>

                </div>

                <div class="contactus-form">

                  <FormItem
                    name="phone"
                    rules={[{ required: true, message: '연락처를 입력해주세요' }]}>
                    <input className="contactus-input" placeholder="연락처" />
                  </FormItem>

                </div>

                <div class="contactus-form">

                  <FormItem
                    name="email"
                    rules={[{ required: true, message: '이메일을 입력해주세요' }]}>
                    <input className="contactus-input" placeholder="이메일" />
                  </FormItem>
                </div>

              </div>

              <div class="contactus-box-right">

                <div class="contactus-form">
                  <FormItem
                    name="content"
                    rules={[{ required: true, message: '내용을 입력해주세요' }]}>
                    <textarea className="contactus-textarea" placeholder="내용" />
                  </FormItem>

                </div>
              </div>

            </div>

            <div class="contactus-btn">

              <div class="contactus-btn-box">
                <Button className="contactus-inputbtn" type="primary" htmlType="submit">
                  S E N D
                  </Button>
              </div>

            </div>

          </Form>


        </div>

        <div class="location">

          <div class="location-box">

            <div class="location-title">

              <div class="location-title-ko">
                블릭트
                  </div>

              <div class="location-title-en">
                BLICT
                  </div>

            </div>


            <div class="location-bar"></div>

            <div class="location-txt">

              <div class="location-txt-box">

                <div class="location-txt-box-name">
                  Office
                      </div>

                <div class="location-txt-box-sub">

                  <div class="location-txt-box-sub-01">
                    서울특별시<br />
                              강남구 논현동 111-22, 3층
                          </div>

                  <div class="location-txt-box-sub-02 desk">
                    ※건물 내에 주차장이 마련되어 있지 않아<br />
                              대중교통 이용을 권장드립니다.
                          </div>

                  <div class="location-txt-box-sub-02 mobile">
                    ※건물 내에 주차장이 마련되어<br />
                              있지 않아 대중교통 이용을
                              <br /> 권장드립니다.
                          </div>

                </div>

              </div>


              <div class="location-txt-box">

                <div class="location-txt-box-name">
                  E-mail
                      </div>

                <div class="location-txt-box-sub">
                  admin@blict.kr

                      </div>

              </div>


              <div class="location-txt-box">

                <div class="location-txt-box-name">
                  Tel
                      </div>

                <div class="location-txt-box-sub">
                  02-546-4261

                      </div>

              </div>


              <div class="location-txt-box">

                <div class="location-txt-box-name">
                  Web
                      </div>

                <div class="location-txt-box-sub">
                  https://www.blict.co.kr
                      </div>

              </div>

            </div>


            <div class="location-bar"></div>

          </div>


          <div class="location-img desk">
            <img src={require("../../img/location-mockup.png").default} />
          </div>



        </div>

        <div class="footer">

          <div class="logo-img">

            <img src={require("../../img/footer-logo.png").default} />
          </div>

          <div class="footer-txt-01">

            <span>대표</span> 김민우 │ <span>사업자등록번호</span> 835-87-01726

          </div>

          <div class="footer-txt-02">

            고객센터 02 546 4261 │ 운영시간 10:00 - 18:00

          </div>

        </div>

        <div
          id="loadingSpinner"
          style={{
            display: "none",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 99999
            // pointerEvents: "none"
          }}>
          <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
        </div>

      </div>
    );
  }
}

export default withAlert()(Main);
