export default {
  appName: 'blict',

  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "30002",
  
  serverProtocol: "https",
  serverIp: "back.blict.net",
  serverPort: "30002",

  clientIp: "localhost",
  clientPort: "3000",

};
